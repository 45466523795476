import FilterBase from './FilterBase'

export default class ProductFilter extends FilterBase {
    Active?: boolean;
    Ids?: string[];
    Shippable?: boolean;
    Type?: boolean;
    Url?: string;
    Limit?: number;
    Createed?: string;
    EndingBefore?: string;
    StartingAfter?: string;

    constructor(init?: Partial<ProductFilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}
